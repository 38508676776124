/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();
  return (
    <div className={classes.pricingSection}>
      <GridContainer>
        <GridItem
          md={6}
          sm={6}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
         
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                Base Static Website 
              </h6>
              <h1 className={classes.cardTitle}>
                <small>$</small>599 <small></small>
              </h1>
              <ul>
                <li>
                  <b>4</b> Fully Customizeable Pages
                </li>
                <li>
                  <b></b> Gallery w/ videos
                </li>
                <li>
                  <b></b> About page
                </li>
                <li>
                  <b></b> Contact Form
                </li>
              </ul>
              <Button href="/contact-us" color="info" round>
                Reach Out !
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card raised pricing color="info">
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Full-Fledged Website</h6>
              <h1 className={classes.cardTitleWhite}>
                <small>$</small>799 <small></small>
              </h1>
              <ul>
                <li>
                  <b>*</b> Base Package Included
                </li>
                <li>
                  <b></b> Login authorization
                </li>
                <li>
                  <b></b> Payments
                </li>
                <li>
                  <b></b> Visual FX
                </li>
              </ul>
              <Button href="/contact-us" color="white" round>
                Reach Out !
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                Feature-Packed Website
              </h6>
              <h1 className={classes.cardTitle}>
                <small>$</small>999 <small></small>
              </h1>
              <ul>
                <li>
                  <b>¤</b> Full-Fledged Package included
                </li>
                <li>
                  <b></b> User Profiles
                </li>
                <li>
                  <b></b> API
                </li>
                <li>
                  <b></b> Dashboard View
                </li>
              </ul>
              <Button href="/contact-us" color="info" round>
                Reach Out !
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
          }
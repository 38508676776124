/*eslint-disable*/

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import cardsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/cardsStyle.js";

import cardsTest from "assets/img/assets-for-demo/cards-test.png";

const useStyles = makeStyles(cardsStyle);

export default function SectionCards() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={7} sm={7}>
            <div className={classes.imageContainer}>
              <img src={cardsTest} alt="views" />
            </div>
          </GridItem>
          <GridItem md={4} sm={5} className={classes.mlAuto}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Specializing in Web Design</h3>
              <h6 className={classes.description}>
                Personal / Business / E-commerce / API 
              </h6>
              <h5 className={classes.description}>
              Allow me to make your website come to life -- I work with the latest technologies available to create a complete user experience.
              I like when things work and when they are sustainable. There is enormous value in a reliable application. 
Take your business to the next level with a modern website, SEO optimized and scalable. Get listed and found on Google; you will have access to site analytics 
so you can reach a much wider audience or a niche -- everything is customizable and made to specification. 
              I am passionate about staying up to date with all the latest trends and tendencies in web design. I will keep you ahead of the competition.
              I am confident about coding in general and will be happy to explain anything you want to know more about.
              </h5>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

/* eslint-disable prettier/prettier */
/* eslint-disable-next-line react/display-name */

import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// import Logo from "assets/img/dc7logo.png";
// @material-ui icons
import Apps from "@material-ui/icons/Apps";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import './Pres.css';
import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{backgroundColor:'#FBFBFB'}}>


      <div className={classes.container}>

        {/* <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h4 className={classes.description}>
              <img src={Logo}/>
            </h4>
          </GridItem>
        </GridContainer> */}
        <div className={classes.features}>
          
        {/* <div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div className="rain">
		<div className="drop"></div>
		<div className="waves">
			<div></div>
			<div></div>
		</div>
		<div className="splash"></div>
		<div className="particles">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div> */}

          <GridContainer justify="center">
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Full-Stack Development"
                description="MERN / LAMP stacks. Serverless/AWS cloud. Customizeable elements"
                icon={Apps}
                iconColor="warning"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Mobile App Development"
                description="Deploy for all platforms. UI/UX for different practical needs. API deployment."
                icon={ViewDay}
                iconColor="primary"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="IT support"
                description="I understand things from a hands-on perspective. CompTIA A+ | Network+ | Security+ | Cloud+ "
                icon={ViewCarousel}
                iconColor="success"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div></div>
    
  );
}
